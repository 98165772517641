/**
 * Axios configuration and interceptor setup for API communication.
 * Handles authentication, error handling, and automatic token management.
 * @module ApiClient
 */

import { symRoundedProblem } from '@quasar/extras/material-symbols-rounded'
import type { AxiosRequestConfig } from 'axios'
import axios from 'axios'
import { Notify } from 'quasar'
import { useUserStore } from 'src/stores/useUserStore'
import { i18n } from 'src/utils/i18n'
import type { Router } from 'vue-router'

// Create axios instance
export const axiosApi = axios.create({
  baseURL: process.env.API_BASE_URL || '/api',
  withCredentials: false,
})

/**
 * Handles user logout by clearing store state
 */
const logout = (): void => {
  const userStore = useUserStore()
  userStore.logout()
}

/**
 * Sets the API token in the request headers if available
 * @param config - Axios request configuration
 * @returns Updated config with authorization header
 */
export const setApiToken = (config: AxiosRequestConfig): void => {
  const userStore = useUserStore()

  if (userStore.token) {
    axiosApi.defaults.headers.common['Authorization'] = `Bearer ${userStore.token}`
    config.headers.Authorization = `Bearer ${userStore.token}`
    return
  }

  setTimeout(() => {
    setApiToken(config)
  }, 250)
}

// Setup request interceptor
axiosApi.interceptors.request.use((config) => {
  setApiToken(config)
  return config
})

/**
 * Initializes axios with router for authentication redirects and error handling
 * @param router - Vue router instance for navigation
 */
export const initializeAxios = (router: Router): void => {
  /**
   * Translates message key using i18n
   * @param message - Message key to translate
   * @returns Translated message
   */
  const getI18nMessage = (message: string): string => {
    return i18n.t(message)
  }

  axiosApi.interceptors.response.use(
    function (response) {
      if (response.data && response.data.message === 'Unauthenticated.') {
        logout()
        localStorage.clear()
        router.push({ path: '/login' })
      }
      return response
    },
    (error) => {
      const { status } = error.response && error.response.status ? error.response : 'OFFLINE'

      if (status === 401 && error.response.config.url !== 'broadcasting/auth') {
        try {
          logout()
          localStorage.clear()

          if (error.response.config.url !== 'user' && router.currentRoute.value.fullPath !== '/login') {
            router.push({ path: '/login' })
            Notify.create({
              color: 'negative',
              position: 'top',
              message: 'Votre session as expirée',
              icon: symRoundedProblem,
            })
          }
        } catch (logoutError) {
          console.error(logoutError)
        }
      }
      if (status === 422) {
        const data = error.response.data
        let message = "Une erreur inconnue s'est produite"
        if (data.message) {
          message = getI18nMessage(data.message)
        }

        Notify.create({
          color: 'negative',
          position: 'center',
          message,
          icon: symRoundedProblem,
        })
      }

      return Promise.reject(error)
    },
  )
}
