import { defineStore } from 'pinia'
import { axiosApi } from 'src/utils/axios'
import { ref } from 'vue'

interface Setting {
  id: string
  name: string
  value: any
  type: string
  description: string
  group: string
  options?: any[]
  default?: any
  validation?: string
  icon?: string
}

export const useServerSettingsStore = defineStore(
  'server-settings-store',
  () => {
    const settings = ref<Setting[]>([])

    const fetchSettings = async () => {
      try {
        const { data } = await axiosApi.get<Setting[]>('settings')
        settings.value = data
      } catch (error) {
        console.error('Failed to fetch settings:', error)
      }
    }

    const getSetting = (name: string) => {
      return settings.value.find((setting) => setting.name === name)
    }

    return {
      settings,
      fetchSettings,
      getSetting,
    }
  },
  { persist: true },
)
