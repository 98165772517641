export default {
  en: {
    welcome: 'Hi, {name}',
    welcome_caption: '{active} active tasks and {completed} completed tasks',
  },
  fr: {
    welcome: 'Bonjour, {name}',
    welcome_caption: '{active} tâches actives et {completed} tâches terminées',
  },
}
 